<template>
  <section class="presse">
    <h1 class="presse_h1">
      Presse
    </h1>
    <PressList
      class="presse__list"
      :items="pressItems"
    />
  </section>
</template>

<script>
import {pressItems} from "@/models/Presse.js"
import PressList from "@/components/PressList.vue"

export default {
  components: {
    PressList
  },

  data() {
    return {
      pressItems
    }
  }
}
</script>

<styles lang="scss">
@use "app";
@use "txt";
@use "vars";

.presse {
  max-width: vars.$max-content-w-1;
  margin: app.$margin-to-nav auto;
  @include txt.bodypadding-h;
}

.presse_h1 {
  @include txt.sitehead;
}

.presse__list {
  margin-top: 4em;
}
</styles>
