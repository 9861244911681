export default class Presse {
  /**
   * @param {Object} x
   * @param {String} x.publishedIn
   * @param {String} x.name
   * @param {String} x.href - link to external publication
   */
  constructor(x) {
    Object.assign(this, x)
  }
}

export const pressItems = [
  new Presse({
    publishedIn: "Stuttgarter Zeitung",
    name: "Auch 2021 ist das 5 die Nummer eins bei TripAdvisor Kundenbewertungen und gewinnt den Traveller's Choice Award.",
    href: "https://www.stuttgarter-zeitung.de/inhalt.gourmetrestaurant-5-in-stuttgart-essen-gehen-statt-in-den-urlaub-fahren.7d0c79ce-7434-4bc5-b23a-6cafc7d89b3b.html"
  }),
  new Presse({
    publishedIn: "Campaya Urlaubsguide",
    name: "Ein Besuch im 5 gehört zu den 12 besten Aktivitäten und Sehenswürdigkeiten in Stuttgart.",
    href: "https://www.campaya.de/aktivitaten-sehenswurdigkeiten-stuttgart.a?9=9"
  }),
  new Presse({
    publishedIn: "Stuttgarter Zeitung",
    name: "Stuttgart ist für Gourmets dreimal spitze: Der Gourmetführer „Der große Guide“ hat in seiner neuesten Auflage wieder Köche und Gastronomien ausgezeichnet.",
    href: "https://www.stuttgarter-zeitung.de/inhalt.gastronomien-in-stuttgart-stuttgart-ist-fuer-gourmets-dreimal-spitze.228d469e-e6d5-448b-9c50-46bdc4142b87.html"
  }),
  new Presse({
    publishedIn: "ahgz",
    name: "Das sind die Travellers-Choice-Gewinner für Restaurants 2019: Die User von Tripadvisor haben ihre Lieblings-Gourmettempel gewählt. In Deutschland liegt das Stuttgarter Restaurant 5 in der Gunst der Gäste vorn.",
    href: "https://www.ahgz.de/news/bewertungsportale-das-sind-die-travellers-choice-gewinner-fuer-restaurants,200012259133.html"
  }),
  new Presse({
    publishedIn: "Varta-Führer",
    name: "Nachgefragt bei... Alexander Dinter: Seit Juli 2018 hat er die alleinige Regie in dem mehrfach ausgezeichneten Gourmet Restaurant.",
    href: "https://www.varta-guide.de/freizeit-guide/genussvoll-schlemmen/nachgefragt-bei-alexander-dinter"
  }),
  new Presse({
    publishedIn: "Welt",
    name: "Dritte Halbzeit: „Zico“ führt jetzt ein Sterne-Restaurant: Der ehemalige Bundesligaprofi Michael Zeyer besitzt mittlerweile in Stuttgart das Sterne-Restaurant „5“.",
    href: "https://www.welt.de/sport/fussball/article123557461/Zico-fuehrt-jetzt-ein-Sterne-Restaurant.html"
  }),
  new Presse({
    publishedIn: "Bild",
    name: "Ex-Profi Zeyer führt jetzt Sterne-Restaurant: Er spielte 146 Mal für Lautern, Duisburg und den VfB in der Bundesliga.",
    href: "https://www.bild.de/sport/fussball/vfb-stuttgart/ex-profi-zeyer-hat-restaurant-mit-michelin-stern-27784138.bild.html"
  })
]
