<template>
  <ul class="press-list">
    <li
      v-for="x in items"
      :key="x.href"
      class="press-list__li"
    >
      <div class="press-list__published-in">
        {{ x.publishedIn }}
      </div>
      <div class="press-list__name">
        {{ x.name }}
      </div>
      <a
        class="press-list__link"
        :href="x.href"
        target="_blank"
      >
        Artikel lesen
      </a>
    </li>
  </ul>
</template>

<script>
import Presse from "@/models/Presse.js"

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
      validator: arr => arr.every(x => x instanceof Presse)
    }
  }
}
</script>

<styles lang="scss">
@use "img";
@use "vars";

.press-list {
  $b: img.$gap solid img.$gap__color;
  border-top: $b;
  border-bottom: $b;
}

.press-list__li {
  margin: 4em 0;
}

.press-list__published-in {
  font-family: vars.$font-fam-deco;
  color: vars.$gold;
  text-transform: uppercase;
}

.press-list__name {
  margin-top: 1em;
}

.press-list__link {
  display: block;
  margin-top: 1em;
}
</styles>
